import React, { Component, Fragment } from "react";
import { routesContext } from "../../../../shared/routes/routes-context";
import phoneImage from "../../../../assets/img/phones.png";
import { getUser } from "../../../../shared/helpers/user-helper";
import { setEvent } from "../../../../firebaseConfig";
export default class LoginForm extends Component {
  static contextType = routesContext;
  render() {
    return (
      <Fragment>
        <div className="create-trial columns">
          <div className="create-trial__title sub-title">Start a trial</div>
          <div
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                height: "100px",
                overflow: "hidden",
                position: "relative",
                width: "110%",
                marginBottom: "10%",
              }}
            >
              <img
                src={phoneImage}
                alt="Phones"
                style={{
                  display: "flex", // This removes the space below the image
                  width: "265px", // This makes the image responsive
                  marginRght: "200px",
                  position: "absolute",
                  top: "0",
                  right: "-10px",
                }}
              ></img>
            </div>
            <ul className="create-trial-list">
              <li>Track your stats</li>
              <li>Easy-to-use digital scorecard</li>
              <li>Apple Watch integration</li>
              <li>3D Course Maps and GPS</li>
            </ul>
          </div>
          <a
            className="btn fill cardinal background-focus"
            href={this.context.trial.path}
            style={{
              "margin-top": "30px",
              padding: "10px",
              width: "65%",
              minWidth: "200px",
            }}
          >
            Start trial now
          </a>
          <br></br>
          <button
            onClick={() => {
              const user = getUser();
              setEvent(
                user,
                "Trial_Sign_Up_Click",
                "Screen_Name",
                "Welcome_Page"
              );
              window.open("https://getahandicap.usga.org");
            }}
            style={{
              textAlign: "center",
              color: " #0088ce",
              fontFamily: "National",
              fontWeight: "600",
              background: "transparent",
              border: "none",
              cursor: "pointer",
              fontSize: "14px",
            }}
          >
            Sign up for a Handicap Index
          </button>
        </div>
      </Fragment>
    );
  }
}
