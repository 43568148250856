import React, { Component, Fragment } from "react";
import Stats from "../Shared/Summary/Stats";
import Banner from "../Shared/Summary/Banner";
import ViewHistory from "../Shared/Summary/ViewHistory";
import RecentScores from "../../../recent-scores/components/RecentScores";
import StartTrialModal from "../../../../shared/components/modals/StartTrialModal";
import PropTypes from "prop-types";
import { routesContext } from "../../../../shared/routes/routes-context";
import Box from "../../../../shared/components/box/Box";
import BoxHead from "../../../../shared/components/box/BoxHead";
import { Helmet } from "react-helmet";
import GolferApi from "../../../../services/golfer-api";
import { getUser } from "../../../../shared/helpers/user-helper";
import { setEvent } from "../../../../firebaseConfig";
import {
  RS_TOTAL_TITLE,
  RS_TOTAL_DESC,
} from "../../../../shared/variables/meta-seo";

class PostTotalScoreSummary extends Component {
  static contextType = routesContext;
  state = {
    trialModalOpen: false,
    modalTitle: "",
    modalContent: "",
    modalSubTitle: "",
    modalSubContent: "",
    icon: "",
    n_modalTitle: "",
    n_modalContent: "",
    n_modalSubTitle: "",
    n_modalSubContent: "",
    n_icon: "",
    next: false,
    thirdScorePosted: null,
  };

  componentDidMount = () => {
    var golfer_id = this.props.history.location.state.score.golfer_id;
    GolferApi.checkTrial(golfer_id, "summary").then((r) => {
      if (r.data.days_left != -1 && r.data.messages) {
        this.setState({
          modalTitle: r.data.messages[0].headline,
          modalContent: r.data.messages[0].copy,
          modalSubContent: r.data.messages[0].sub_copy,
          modalSubTitle: r.data.messages[0].sub_headline,
          icon: r.data.messages[0].icon,
          trialModalOpen: true,
        });
        if (r.data.messageCount == 2) {
          this.setState({
            n_modalTitle: r.data.messages[1].headline,
            n_modalContent: r.data.messages[1].copy,
            n_modalSubContent: r.data.messages[1].sub_copy,
            n_modalSubTitle: r.data.messages[1].sub_headline,
            n_icon: r.data.messages[1].icon,
            next: true,
            thirdScorePosted: true,
          });
        }
      }
    });
  };

  onConfirmModal = () => {
    if (this.state.next) {
      this.setState({
        modalTitle: this.state.n_modalTitle,
        modalContent: this.state.n_modalContent,
        modalSubContent: this.state.n_modalSubContent,
        modalSubTitle: this.state.n_modalSubTitle,
        icon: this.state.n_icon,
        next: false,
      });
    } else {
      this.setState({ trialModalOpen: false });
    }
    const user = getUser();
    setEvent(user, "Trial_Sign_Up_Click", "Screen_Name", "Pop_Up");
    window.open("https://getahandicap.usga.org");
  };

  onCloseModal = () => {
    if (this.state.next) {
      this.setState({
        modalTitle: this.state.n_modalTitle,
        modalContent: this.state.n_modalContent,
        modalSubContent: this.state.n_modalSubContent,
        modalSubTitle: this.state.n_modalSubTitle,
        icon: this.state.n_icon,
        next: false,
      });
    } else {
      this.setState({ trialModalOpen: false });
    }
  };

  getScore = () => {
    const { history } = this.props;
    if (history.location.state && history.location.state.score) {
      return history.location.state.score;
    } else {
      return null;
    }
  };

  getCourseDetails = () => {
    const { history } = this.props;
    if (history.location.state && history.location.state.courseDetails) {
      return history.location.state.courseDetails;
    } else {
      return null;
    }
  };

  render = () => {
    return (
      <Fragment>
        <Helmet>
          <title>{RS_TOTAL_TITLE}</title>
          <meta name="description" content={RS_TOTAL_DESC} />
        </Helmet>
        <Box>
          <BoxHead title="Round Summary" />
          <Banner />
          <Stats
            score={this.getScore()}
            courseDetails={this.getCourseDetails()}
            hideDeleteButton={this.state.thirdScorePosted}
            isAdvStatsOn={false}
          />
          <br />
          <br />
          <BoxHead title="Recent Scores" />
        </Box>
        <RecentScores defaultScoresPerPage={10} />
        <ViewHistory onViewScoreHistory={() => this.context.stats.navigate()} />
        <StartTrialModal
          title={this.state.modalTitle}
          content={this.state.modalContent}
          subtitle={this.state.modalSubTitle}
          subcontent={this.state.modalSubContent}
          icon={this.state.icon}
          isOpen={this.state.trialModalOpen}
          onConfirmModal={this.onConfirmModal}
          onCloseModal={this.onCloseModal}
        />
      </Fragment>
    );
  };
}

PostTotalScoreSummary.propTypes = {
  history: PropTypes.object.isRequired,
};

export default PostTotalScoreSummary;
